<template>
	<v-btn color="primary" class="mb-2" v-if="room" block @click="go">Room Monitor</v-btn>
</template>

<script>
export default {
	name: "RoomMonitor",
    props: {
        room: { type: Object }
    },
	methods: {
		go() {
			const script = document.createElement("script");
			script.src =
				"https://cdn.jsdelivr.net/npm/@twilio/video-room-monitor/dist/browser/twilio-video-room-monitor.min.js";
			script.onload = () => {
				// Register your Twilio Video Room here
				window.Twilio.VideoRoomMonitor.registerVideoRoom(this.room);
				window.Twilio.VideoRoomMonitor.openMonitor();
			};
			document.body.appendChild(script);
		},
	},
};
</script>
